import { Outlet } from 'react-router-dom';
import styles from './style.module.css';
import logo from '../../assets/images/logo-blue.webp'

export default function Layout() {
  return (
    <div className={styles.main}>
      <Outlet />
    </div>
  )
}