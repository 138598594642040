import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import PageLoading from "../components/loading/page";
import Layout from "../components/layout";

const Home = lazy(() => import("../pages/home"));
const ErrorPage = lazy(() => import("../pages/error"));

export default function Router() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/">
            <Route index={true} element={
              <Home />
            } />
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}