import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  promotionCategories: null,
  activePromotionCategory: null
}

const promotionCategorySlice = createSlice({
  name: 'promotionCategories',
  initialState,
  reducers: {
    setPromotionCategories: (state, action) => {
      state.promotionCategories = action.payload;
    },
    setActivePromotionCategory: (state, action) => {
      state.activePromotionCategory = action.payload;
      return state;
    }
  }
});

const { actions, reducer } = promotionCategorySlice;
export const { setPromotionCategories, setActivePromotionCategory } = actions;
export default reducer;